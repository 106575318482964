@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gramatika";
  src: url("../src/Assets/fonts/Regular.ttf");
}

html {
  scroll-behavior: smooth;
}

* g {
  outline: solid 4px blue;
  outline-offset: 5px;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "Gramatika";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}
